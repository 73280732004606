<template>
  <div>
    <week-table />
  </div>
</template>
<script>
import WeekTable from '@/views/main/warehouse/main-order/components/tables/WeekTable.vue'
import config from '../../config'

export default {
  name: 'Week',
  components: { WeekTable },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/SET_WAREHOUSE_DATE_PICKER`, { value: new Date() })
  },
  setup() {
    const { MODULE_NAME } = config()
    return {
      MODULE_NAME,
    }
  },
}
</script>
